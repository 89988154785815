<template>
  <div id="app">
    <div class="utilBar"></div>
    <div class="customMenu">
        <div class="customHeader">
            <div class="image-group">
                <a href="https://www.vanguard.com" target="_blank" class="customLink" title="The Vanguard Group"
                    aria-label="The Vanguard Group" aria-describedby="navBarLogo_desc">
                    <img class="customHeaderImg" src="./../assets/VG_WM_S_RGB_v3.png" alt="vanguard logo">
                    <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
                </a>
            </div>
        </div>
    </div>
    <div class="centerContent">
        <div id="home" class="one-column-layout" role="main" aria-label="Main content" tabindex="0">
            <div class="error-page">
                <div class="content-text">
                    <h1 class="image-header-text">Vanguard Portal</h1>
                    <div class="text-container">
                        <p class="error-text">Are you looking for Crewnet?</p>
                        <p class="inline-text">Connect using a Vanguard-issued system and verify the internal network
                            connection. Then, <a :href="environment.crewnetUri" class="login-link" title="Go to Crewnet"
                            aria-label="Go to crewnet">
                            click here
                        </a> or use the browser Home button to access CrewNet </p>
                    </div>
                    <div class="link-container">
                        <p class="image-text">Are you looking for the <span class="text-font">Retiree Portal? </span>
                        </p>
                        <a :href="environment.loginUri" class="login-link redirect-link" title="Log on to retiree portal"
                            aria-label="Go to retiree portal">
                            Go to Retiree Portal
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="customFooter" role="contentinfo">
        <a href="https://www.vanguard.com" target="_blank" title="The Vanguard Group" aria-label="The Vanguard Group"
            aria-describedby="navBarLogo_desc">
            <img class="customFooterImg" src="./../assets/VG_WM_S_RGB_v3.png">
            <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
        </a>
        <p>© 2000–{{ currentYear }} The Vanguard Group, Inc. All rights reserved.
        </p>
        <p>Your use of this site signifies that you accept our <a class="customFooterLink"
                href="https://investor.vanguard.com/terms-conditions" title="Terms and conditions of use"
                aria-label="Terms and conditions of use">Terms and conditions of use.</a>
        </p>
        <p><a class="customFooterLink" href="https://investor.vanguard.com/security" title="Security"
                aria-label="Security">Security.</a></p>
    </div>
</div>
</template>

<script>
import urls from '../config'
export default {
  name: 'home',
  data: function () {
    return {
      environment: urls.urls,
      currentYear: null
    }
   },
  mounted(){this.getCurrentYear();},
  created() { },
  methods: {
    getCurrentYear() {
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
    }
  }
}
</script>
<style>
@font-face {
    font-family: 'MarkProHeavy';
    src: url('./../assets/fonts/MarkPro-Heavy.otf') format('opentype');
}

@font-face {
    font-family: 'MarkProBold';
    src: url('./../assets/fonts/MarkPro-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'MarkPro';
    src: url('./../assets/fonts/MarkPro.otf') format('opentype');
}

body {
    font-family: 'MarkPro', Arial, sans-serif !important;
}

.image-header-text {
    font-size: 57px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    margin-bottom: 0.5em;
    font-family: 'MarkProHeavy', Arial, sans-serif !important;
}

.customBtn {
    background-color: #96151d !important;
}

.customBtn:hover {
    background-color: #760a11 !important;
}

.button-container {
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.login-link {
    font-family: 'MarkProBold', Arial, sans-serif !important;
    color: #0074c9;
    display: inline-flex;
}

.login-link:hover {
    text-decoration: underline;
    text-underline-offset: .3125rem;
}

.two-column-layout {
    display: flex;
    min-height: 650px;
    margin-bottom: 80px;
}


.error-page {
    background-color: #fff7de;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

:focus {
    outline: 2px solid #0F62C5;
    outline-offset: 0.15rem;
}

.custom {
    width: 30px;
}

.customFooterImg {
    height: 45px;
    width: 130px;
    margin-bottom: 20px;
}

.customHeader {
    display: flex;
    align-items: center;
    flex-direction: inherit;
    width: 25% !important;
    justify-content: center;
}

.image-group,
.button-group {
    display: flex;
    align-items: center;
}

.customLink {
    padding: 1em;
}

.divider {
    border-left: 2px solid #e3e8e8;
}

.utilBar {
    min-height: 35px;
    border-bottom: 1px solid #e3e8e8;
}

.customMenu {
    display: flex;
    position: relative !important;
    border-bottom: 1px solid #e3e8e8 !important;
    background: #fff !important;
    min-height: 5em !important;
}

.d-none {
    display: none;
}

.customFooter {
    font-size: 14px;
    line-height: 18px;
    color: #040505;
    background: #f9f9f9;
    padding: 3% 5%;
    overflow: auto;
}

.customFooterLink {
    text-decoration: underline;
    color: #040505;
    text-underline-offset: .3125rem;
}

.customFooterLink:hover {
    text-decoration: underline;
    color: #040505;
}

.text-font {
    font-family: 'MarkProBold', Arial, sans-serif !important;
}

.redirect-link {
    font-family: 'MarkPro', Arial, sans-serif !important;
}

.one-column-layout {
    display: flex;
    min-height: 500px;
    margin-bottom: 2em;
}

.inline-text {
    font-size: 20px;
    line-height: 23px;
}

.text-container,
.link-container {
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 1.3em;
}

.link-container {
    font-size: 20px;
    line-height: 23px;
}

.error-text,
.image-text {
    margin-bottom: 0.3em;
}

.image-text {
    display: inline;
    margin-bottom: 1em;
}

.error-text {
    font-family: 'MarkProBold', Arial, sans-serif !important;
}

.customHeaderImg {
    height: 45px;
    width: 130px;
}
.content-text{
    padding: 0 12%;
}
</style>
